import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import OtherLinks from '../../components/otherLinks/otherLinks'

import p1 from '../../images/pages/abacusHR/Abacus HR Prozesse.png'
import p2 from '../../images/pages/abacusHR/Abacus HR Clients.png'

const links = [
    {
        name: 'Abacus HR',
        desc: 'Abacus HR',
        link: 'https://www.abacus.ch/produkte/personal/human-resources'
    },
    {
        name: 'Abacus Lohn',
        desc: 'Abacus Lohn',
        link: 'https://www.abacus.ch/de/produkte/enterprise/lohnsoftware/'
    },
    {
        name: 'Abacus HR Portal',
        desc: 'Abacus HR Portal',
        link: 'https://hr-portal.ch/'
    }
]

const abacusHR = () => (
    <Layout>
        <section id="personalinformationssystem" className="section-wrapper">
            <header className="section-header">
                <h3>Abacus HR</h3>
            </header>
            <div className="post" id="overview">
                <p>
                    Ein erfolgreiches Unternehmen braucht engagierte und fähige Mitarbeitende. Sie zu fördern, zu motivieren und ihren Fähigkeiten entsprechend
                    einzusetzen, stellt hohe Anforderungen an die Personalverantwortlichen. Eine flexible und vielseitig einsetzbare Suite für alle Belange der
                    Personalarbeit ist ein wichtiges Arbeitsinstrument und entlastet das HR, sowie die ganze Firma. Abacus HR unterstützt Sie personelle Ressourcen
                    zu verwalten und die Talente und das Wissen Ihrer Mitarbeitenden optimal einzusetzen.
                </p>
                <img src={p1} alt="Abacus Prozesse" width="100%" />
                <p>
                    Abacus HR unterstützt eine Vielzahl von HR Prozessen und es werden laufend weitere Prozesse entwickelt. Zudem ist es möglich, firmeneigene
                    Prozesse auf Basis der Business Process Engine einzubauen.
                </p>
                <img src={p2} alt="Abacus Clients" width="100%" />
                <p>
                    Abacus HR kann über verschiedene Clients erreicht werden. Der Abacus ULC Client ist für den Fachanwender gedacht. Vorgesetzte und Mitarbeitende
                    greifen entweder über das Portal (MyAbacus) oder über die Mobile App AbaClick zu. AbaClock ist das Zeiterfassungsterminal für Mitarbeitende.
                </p>
            </div>

            <OtherLinks links={links} />

        </section>
    </Layout>
)


export default abacusHR
